import type { UserEventWithDataUpdate } from 'shared/types';
import getServerBaseUrl from 'shared/utils/getServerBaseUrl.platform';
// eslint-disable-next-line import/no-cycle
import requestWithAuth from 'shared/utils/requestWithAuth.platformIncludingExtension';

import makeExtensionLogger from '../common/makeExtensionLogger';

const logger = makeExtensionLogger(__filename);

export default async function sendStateUpdateToApi(event: UserEventWithDataUpdate): Promise<void> {
  logger.debug(event.name, { event });

  await requestWithAuth(`${getServerBaseUrl()}/reader/api/state/update/`, {
    body: JSON.stringify({
      credentials: 'include',
      events: [event],
      mode: 'cors',
      schemaVersion: 9,
    }),
    method: 'POST',
  });
}
