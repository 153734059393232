import Database from '../../database/Database';
import eventEmitter from '../eventEmitter';
// eslint-disable-next-line import/no-cycle
import handleStateUpdateSideEffects from '../handleStateUpdateSideEffects';
import { additionalMiddleware } from './additionalMiddleware';

export default new Database({
  eventEmitter,
  handleStateUpdateSideEffects,
  isInForeground: true,
  additionalMiddleware,
});
